import { create } from "zustand"
import { SearchParams } from "@customTypes/SearchParam"
import { PageNames } from "interfaces/config"
import { columnVisibilityReducer } from "./reducers/columnVisibilityReducer";
import { ReactNode } from "react"
import { persist, createJSONStorage } from 'zustand/middleware'
import { TableConfig } from "interfaces/config";
import {
    SavedSearchConfigArgs,
    FilterStatus,
    FilterType,
    FilterType2,
    Filters,
    Pagination,
    PaginationState,
    TableHeaderCell,
    TableHeaderCells,
    HeaderCell,
    HeaderCells
} from "./interfaces"
import { TableNames } from "customEnums/TableNames";
import { TableBody } from "@mui/material";

export type Page = {
    [key in PageNames]?: {
        [key in TableNames]?: {
            columnVisibility: {
                [key: string]: boolean
            },
            filterStatus?: FilterType<string>,
            filters?: Filters
        }

    }
}

export interface ColumnState {
    page: any //set to Page
}

export interface ConfigServiceState {
    searchData: any,
    alertData: any,
    loadingConfig: boolean,
    postedConfig: boolean,
    errorConfig: boolean
}

export enum Components {
    CUSTOM_FILTER = "CustomFilter"
}

export type ComponentConfig = {
    [key in Components]: {
        handleRadioChange: Function,
        selectedValue: string,
    }
}

export interface HeaderButtonConfig {
    handler: Function
    headerButtonIcon: React.ReactNode | null
    buttonLabel: string
    disabled: boolean
    text: string
}

export enum ApplicantDetailsTable {
    STATUS_HISTORY = "status-history",
    ADJUDICATION_DOCS = "adjudication-docs",
    OUTGOING_TRANSMISSION = "outgoing-transmission",
    INCOMING_RESPONSE = "incoming-response",
    AUDIT_HISTORY = "audit-history",
}

export enum RapbackMaintenance {
    RB_MAINTENANCE = "rapback-maintenance",
    RB_AUDIT_HISTORY = "rb-sub-audit-history"
}

export enum RapbackSummary {
    RAPBACK_SUMMARY = "rb-summary-audit-history"
}

export enum TransactionSummary {
    TRANSACTION_SUMMARY = "transaction-summary",
    REJECT_CODES = "reject-codes"
}

/*    config: any,
    tableName?: string,
    tableHeaderCells?: TableHeaderCells
    headerCells: HeaderCells,
    transactionViewing?: {
        hardLimit: number
        softLimit: number
        extendDays: number
    },
    userRoles?: any,
    filterList?: any,
    filter?: any,
    manageColumns?: boolean,
    advancedSearch?: boolean,
    usersPermissions?: any,
    savedSearches?: any,
    dispatchSearch?: Function
}*/

export interface TableState {
    defaultOrderBy: string,
    tableConfig: TableConfig,
    buttonCellHandlers: any
    //handleCustomRefetch: Function //UserManagement
    currentDataIndex: string
    customFilterAnchorEl: null | HTMLElement
    currentCell: string
    componentConfig: ComponentConfig
    extendModal: boolean
    configService: any
    detailsTable: string[]
    pagination: Pagination
    columnState: ColumnState
    formState: any
    headerButtonConfig: HeaderButtonConfig
    searchParams: SearchParams
    loadConfig: boolean //Not used
    closeAlert: boolean
    savedSearchConfigArgs: SavedSearchConfigArgs
    selected: Array<string>
    tcns: Array<any>
    value: Array<any>
    datePlaceholder: boolean
    //reducer: Function
    //formReducer: Function
    //setReducer: any
    //setFormReducer: any
    advancedSearch: boolean //Enables or disables advanced search
    //resetTable: Function
    openNotification: boolean
    currentRow
    alertTitle: string //AlertStore
    alertMessage: string //AlertStore
    displayNoSearchResults: boolean
    open: boolean
    pageName: string,
    //formValuesDispatch: any
    configLoaded: string[] //ConfigServiceStore
    isDelete: boolean //ConfigServiceStore
    configFile: string //ConfigServiceStore
    configBody: any //ConfigServiceStore
    isPost: boolean //ConfigServiceStore
    simpleSearchRan: boolean
    recordId: any
    extendTCN: string
    severity: any
    createUserStatus: any
    //openAuditHistory: any
    actionsConfig: { actionIcon?: ReactNode, actionLabel: string, actionHandler: Function }[]
    outstandingRapback: any
    needsAttention: boolean,
    applicantName: string
    transactionDate: any
    receivedResponse: boolean,
    //handleOpenRejectDrawer: Function
    loadingConfig: boolean
    internalSearchArray: any
    rowClicked: boolean
    userActive: boolean
    applicantDetailFields: object
    applicantHeader: any,
    runFilters: boolean,
    filtersApplied: boolean,
    columnStateDispatch: Function
    zoomLevel: number,
}

const initialState: TableState = {
    userActive: false,
    rowClicked: false,
    tableConfig: {
        /*[TableNames.TRANSACTION_SUMMARY]: {
            tableName: "",
            tableHeaderCells: [],
            columnVisibility: {},
            transactionViewing: {
                hardLimit: 120,
                softLimit: 90,
                extendDays: 30,
                searchOptions: {
                    limitLowerSearchDate: false
                }
            },
            filterList: [],
            advancedSearch: true,
            filter: true,
            manageColumns: true,
            useQueryPagination: true,
        }*/
    },
    buttonCellHandlers: {},
    internalSearchArray: [],
    loadingConfig: false,
    outstandingRapback: {
        preNotification: false,
        validations: false
    },
    needsAttention: false,
    pageName: "",
    actionsConfig: [{ actionIcon: <div />, actionLabel: "", actionHandler: () => { } }],
    configLoaded: [],
    currentCell: "",
    currentDataIndex: "",
    customFilterAnchorEl: null,
    currentRow: null,
    openNotification: false,
    alertTitle: "",
    alertMessage: "",
    displayNoSearchResults: false,
    selected: [],
    tcns: [],
    open: false,
    recordId: null,
    componentConfig: {
        [Components.CUSTOM_FILTER]: {
            selectedValue: "",
            handleRadioChange: () => { }
        },
    },
    extendModal: false,
    configService: {
        closedAlert: true,
        searchData: {},
        alertData: {},
        rfpListData: {},
        loadingConfig: false,
        postedConfig: false,
        errorConfig: false
    },
    detailsTable: ['incoming-responses', 'outgoing-transmission', 'status-history', 'adjudication-documents'],
    defaultOrderBy: "",
    pagination: {
        [TableNames.TRANSACTION_SUMMARY]: {
            page: 1,
            rowsPerPage: 25,
            sortOrder: "desc",
            orderBy: ""
        },
        [TableNames.REJECT_CODES]: {
            page: 0,
            rowsPerPage: 5,
            sortOrder: "desc",
            orderBy: ""
        },
        [TableNames.RAPBACK_SUMMARY]: {
            page: 1,
            rowsPerPage: 25,
            sortOrder: "desc",
            orderBy: ""
        },
        [TableNames.RB_SUMMARY_AUDIT_HISTORY]: {
            page: 0,
            rowsPerPage: 5,
            sortOrder: "desc",
            orderBy: ""
        },
        [TableNames.RAPBACK_MAINTENANCE]: {
            page: 1,
            rowsPerPage: 25,
            sortOrder: "desc",
            orderBy: ""
        },
        [TableNames.RB_SUB_AUDIT_HISTORY]: {
            page: 0,
            rowsPerPage: 5,
            sortOrder: "desc",
            orderBy: ""
        },
        [TableNames.RAPBACK_VALIDATIONS]: {
            page: 1,
            rowsPerPage: 25,
            sortOrder: "desc",
            orderBy: ""
        },
        [TableNames.RAPBACK_PRENOTIFICATIONS]: {
            page: 1,
            rowsPerPage: 25,
            sortOrder: "desc",
            orderBy: ""
        },
        [TableNames.AGENCY_MANAGEMENT]: {
            page: 1,
            rowsPerPage: 25,
            sortOrder: "desc",
            orderBy: ""
        },
        [TableNames.USER_MANAGEMENT]: {
            page: 0,
            rowsPerPage: 25,
            sortOrder: "desc",
            orderBy: ""
        },
        [TableNames.BATCH_PRINTING]: {
            page: 1,
            rowsPerPage: 25,
            sortOrder: "desc",
            orderBy: ""
        },
        [TableNames.AUDIT_HISTORY]: {
            page: 0,
            rowsPerPage: 10,
            sortOrder: "desc",
            orderBy: ""
        },
        [TableNames.STATUS_HISTORY]: {
            page: 0,
            rowsPerPage: 10,
            sortOrder: "desc",
            orderBy: ""
        },
        [TableNames.ADJUDICATION_DOCS]: {
            page: 0,
            rowsPerPage: 5,
            sortOrder: "desc",
            orderBy: ""
        },
        [TableNames.OUTGOING_TRANSMISSION]: {
            page: 0,
            rowsPerPage: 5,
            sortOrder: "desc",
            orderBy: ""
        },
        [TableNames.INCOMING_RESPONSE]: {
            page: 0,
            rowsPerPage: 5,
            sortOrder: "desc",
            orderBy: ""
        },
    },
    headerButtonConfig: {
        handler: () => { },
        headerButtonIcon: null,
        buttonLabel: "",
        disabled: false,
        text: ""
    },
    columnStateDispatch: () => { },
    columnState: {
        page: {
            [PageNames.TRANSACTION_SUMMARY]: {
                [TableNames.TRANSACTION_SUMMARY]: {
                    columnVisibility: {
                        fullName: false,
                        firstName: true,
                        middleName: false,
                        lastName: true,
                        ueid: true,
                        stateRFPCode: true,
                        stateRFPLiteral: true,
                        status: true,
                        hitNoHit: true,
                        transactionDate: true,
                        tcn: true,
                        agency: true,
                        secondaryOri: true,
                        lastViewedDate: true,
                        ssn: true,
                        dob: false,
                        race: false,
                        gender: false,
                        actions: true,
                        rbSub: false
                    },
                    filters: [

                    ],
                },
                [TableNames.REJECT_CODES]: {
                    columnVisibility: {
                        rejectIndex: true,
                        rejectCode: true,
                        rejectDescription: true
                    },
                    filters: [

                    ],
                }
            },
            [PageNames.APPLICANT_DETAILS]: {
                [TableNames.AUDIT_HISTORY]: {
                    columnVisibility: {
                        date: true,
                        operator: true,
                        location: true,
                        action: true
                    },
                    filters: []
                },
                [TableNames.STATUS_HISTORY]: {
                    columnVisibility: {
                        status: true,
                        statusDate: true
                    },
                    filters: []
                },
                [TableNames.ADJUDICATION_DOCS]: {
                    columnVisibility: {
                        addedDate: true,
                        decision: true,
                        fileNameControl: true,
                        actions: true
                    },
                    filters: []
                },
                [TableNames.OUTGOING_TRANSMISSION]: {
                    columnVisibility: {
                        status: true,
                        agency: true,
                        date: true
                    },
                    filters: []
                },
                [TableNames.INCOMING_RESPONSE]: {
                    columnVisibility: {
                        date: true,
                        type1Field7: true,
                        type2Field59: true,
                    },
                    filters: []
                },


            },
            [PageNames.RAPBACK_MAINTENANCE]: {
                [TableNames.RAPBACK_MAINTENANCE]: {
                    columnVisibility: {
                        fullName: true,
                        dob: false, //or dateOfBirth
                        rapbackSubId: true,
                        ori: true, //or primaryOri
                        //secondaryOri: true, might be needed
                        subscriptionDate: true,
                        expirationDate: true,
                        deletionDate: true,
                        status: true,
                        actions: true,
                    },
                    filters: []
                },
                [TableNames.RB_SUB_AUDIT_HISTORY]: {
                    columnVisibility: {
                        operator: true,
                        location: true,
                        date: true,
                        action: true
                    },
                    filters: []
                }

            },
            [PageNames.RAPBACK_PRENOTIFICATIONS]: {
                [TableNames.RAPBACK_PRENOTIFICATIONS]: {
                    columnVisibility: {
                        fullName: true,
                        dob: false,
                        rapbackId: true,
                        primaryOri: true,
                        secondaryOri: true,
                        subscriptionDate: true,
                        expirationDate: true,
                        deletionDate: true,
                        status: true,
                        actions: true
                    },
                    filters: []
                }


            },
            [PageNames.RAPBACK_VALIDATIONS]: {
                [TableNames.RAPBACK_VALIDATIONS]: {
                    columnVisibility: {
                        fullName: true,
                        dob: false,
                        rapbackId: true,
                        primaryOri: true,
                        secondaryOri: true,
                        subscriptionDate: true,
                        expirationDate: true,
                        unsubscribedDate: true,
                        status: true,
                        actions: true
                    },
                    filters: [
                        {
                            filterLabel: "Time Remaining",
                            filterColumn: "timeRemaining",
                            filterIsBool: false,
                            filterIsNumber: true,
                            filterValues: {
                                11: {
                                    selected: true,
                                    label: "11 - 60 Days"
                                },
                                10: {
                                    selected: true,
                                    label: "2 - 10 Days"
                                },
                                1: {
                                    selected: true,
                                    label: "1 Day"
                                }
                            }
                        }
                    ]
                }

            },
            [PageNames.RAPBACK_SUMMARY]: {
                [TableNames.RAPBACK_SUMMARY]: {
                    columnVisibility: {
                        firstName: true,
                        middleName: false,
                        lastName: true,
                        reason: true,
                        ssn: true,
                        insertDate: true,
                        sid: true,
                        isHit: false,
                        oris: true,
                        lastViewedDate: true,
                        actions: true
                    },
                    filters: [
                        {
                            filterLabel: "Last Viewed",
                            filterColumn: "viewed",
                            filterIsBool: true,
                            filterValues: {
                                viewed: { selected: true, label: "Viewed", boolMap: true },
                                notViewed: { selected: true, label: "Not Viewed", boolMap: false }
                            }
                        }
                    ]
                },
                [TableNames.RB_SUMMARY_AUDIT_HISTORY]: {
                    columnVisibility: {
                        operator: true,
                        location: true,
                        date: true,
                        action: true
                    },
                    filters: []
                }

            },
            [PageNames.BATCH_PRINTING]: {
                [TableNames.BATCH_PRINTING]: {
                    columnVisibility: {
                        checkbox: true,
                        firstName: true,
                        lastName: true,
                        reasonFingerPrinted: true,
                        status: true,
                        transactionDate: true,
                        transactionNumber: true,
                        ori: true
                    },
                    filters: []
                }

            },
            [PageNames.AGENCY_MANAGEMENT]: {
                [TableNames.AGENCY_MANAGEMENT]: {
                    columnVisibility: {
                        ori: true,
                        secondaryOri: true,
                        reasonFingerPrinted: true,
                        agencyName: true,
                        distributionMethod: true,
                        agencyEmail: true,
                        agencyAddress: true,
                        agencyCity: true,
                        agencyState: true,
                        agencyZipcode: true
                    },
                    filters: []
                }


            },
            [PageNames.USER_MANAGEMENT]: {
                [TableNames.USER_MANAGEMENT]: {
                    columnVisibility: {
                        fullName: true,
                        loginName: true,
                        securityLevel: true,
                        status: true,
                        emailAddress: true,
                        primaryOri: true,
                        secondaryOri: true,
                        reasonFingerprinted: true,
                        actions: true
                    },
                    filters: []
                }

            }

        }
    },
    formState: {},
    searchParams: [],
    loadConfig: false,
    closeAlert: false,
    savedSearchConfigArgs: {
        configFile: "alert-closed.json",
        configBody: {},
        isGet: true,
        isPost: false,
        isDelete: false
    },
    datePlaceholder: false,
    value: [],
    advancedSearch: false,
    isDelete: false,
    configFile: 'sticky-settings.json',
    configBody: {},
    isPost: false,
    simpleSearchRan: false,
    extendTCN: "",
    severity: undefined,
    createUserStatus: undefined,
    applicantName: "",
    transactionDate: "",
    receivedResponse: false,
    applicantDetailFields: {},
    applicantHeader: [],
    runFilters: false,
    filtersApplied: false,
    zoomLevel: 1,
}

export const useTableStore = create(
    persist(
        (set, get) => ({
            ...initialState,
            columnState: {
                ...initialState.columnState,
            },
            setDefaultOrderBy: (defaultOrderBy: string) => set({defaultOrderBy}),
            setZoomLevel: (zoomLevel: number) => set({zoomLevel}),
            columnStateDispatch: args => set(state => columnVisibilityReducer(state, args)),
            resetTable: () => { },
            setRunFilters: (runFilters: boolean) => set({ runFilters }),
            setFiltersApplied: (filtersApplied: boolean) => set({ filtersApplied }),
            setApplicantHeader: (applicantHeader: any) => set({ applicantHeader }),
            setApplicantDetailFields: (applicantDetailFields: any) => set({ applicantDetailFields }),
            dispatchFormValues: () => { },
            setUserActive: (userActive: boolean) => set({ userActive }),
            setRowClicked: (rowClicked: boolean) => set({ rowClicked }),
            setButtonCellHandlers: (buttonCellHandlers: any) => set({ buttonCellHandlers }),
            setTableConfig: (tableConfig: TableConfig) => set({ tableConfig }),
            setInternalSearchArray: (internalSearchArray: any) => set({ internalSearchArray }),
            handleOpenRejectDrawer: () => { },
            setHandleOpenRejectDrawer: (handleOpenRejectDrawer: any) => set({ handleOpenRejectDrawer }),
            setLoadingConfig: (loadingConfig: any) => set({ loadingConfig }),
            openAuditHistory: () => { },
            setOpenAuditHistory: (openAuditHistory: any) => set({ openAuditHistory }),
            setOutStandingRapback: (outstandingRapback: any) => set({ outstandingRapback }),
            setNeedsAttention: (needsAttention: any) => set({ needsAttention }),
            setPageName: (pageName: string) => set({ pageName }),
            setActionsConfig: (actionsConfig: { actionIcon?: ReactNode, actionLabel: string, actionHandler: Function }[]) => set({ actionsConfig }),
            handleCustomRefetch: () => { },
            setHandleCustomRefetch: (handleCustomRefetch: Function) => ({ handleCustomRefetch }),
            setConfigLoaded: (configLoaded: string[]) => set({ configLoaded }),
            setOpenPrint: (open: boolean) => set({ open }),
            setSelected: (selected) => set({ selected }),
            setTcns: (tcns: Array<any>) => set({ tcns }),
            setRecordId: (recordId) => set({ recordId }),
            setDisplayNoSearchResults: (displayNoSearchResults) => set({ displayNoSearchResults }),
            setPage: (page) => set({ page }),
            setRowsPerPage: (rowsPerPage) => set({ rowsPerPage }),
            setAlertMessage: (alertMessage) => set({ alertMessage }),
            setAlertTitle: (alertTitle) => set({ alertTitle }),
            setCurrentRow: (currentRow) => set({ currentRow }),
            setCustomFilterAnchorEl: (customFilterAnchorEl: null | HTMLElement) => set({ customFilterAnchorEl }),
            setCurrentCell: (currentCell: string | undefined) => set({ currentCell }),
            setCurrentDataIndex: (currentDataIndex: string) => set({ currentDataIndex }),
            setOpenNotification: (openNotification: boolean) => set({ openNotification }),
            setExtendModal: (extendModal: boolean) => set({ extendModal }),
            setConfigService: (configService: ConfigServiceState) => set({ configService }),
            setPagination: (pagination: Pagination) => set({ pagination }),
            setResetTable: (resetTable: Function) => set({ resetTable }),
            reducer: () => { },
            formReducer: () => { },
            setReducer: (reducer: (state, action) => {}) => set({ reducer }),
            setFormReducer: (formReducer: (state, action) => {}) => set({ formReducer }),
            formValuesDispatch: args => set(state => state.formReducer(state, args)),
            setColumnState: (columnState: ColumnState) => set({ columnState }),
            setAdvancedSearch: (advancedSearch: boolean) => set({ advancedSearch }),
            setFormState: (formState: any) => set({ formState }),
            setComponent: (componentConfig: any) => set({ componentConfig }),
            setHeaderButtonConfig: (headerButtonConfig: any) => set({ headerButtonConfig }),
            setDispatchFormValues: (dispatchFormValues: React.Dispatch<any>) => set({ dispatchFormValues }),
            setSearchParams: (searchParams: SearchParams) => set({ searchParams }),
            setLoadConfig: (loadConfig: boolean) => set({ loadConfig }),
            setCloseAlert: (closeAlert: boolean) => set({ closeAlert }),
            setSavedSearchConfigArgs: (savedSearchConfigArgs: SavedSearchConfigArgs) => set({ savedSearchConfigArgs }),
            setIsDelete: (isDelete: boolean) => set({ isDelete }),
            setConfigFile: (configFile: string) => set({ configFile }),
            setConfigBody: (configBody: any) => set({ configBody }),
            setIsPost: (isPost: boolean) => set({ isPost }),
            setSimpleSearchRan: (simpleSearchRan: boolean) => set({ simpleSearchRan }),
            setExtendTCN: (extendTCN: string) => set({ extendTCN }),
            setSeverity: (severity: string) => set({ severity }),
            setCreateUserStatus: (createUserStatus: any) => set({ createUserStatus }),
            setApplicantName: (applicantName: string) => set({ applicantName }),
            setTransactionDate: (transactionDate: any) => set({ transactionDate }),
            setReceivedResponse: (receivedResponse: any) => set({ receivedResponse }),
            resetTableStore: () => {
                set(initialState)
            }
        }),
        {
            name: "tableStore",
            storage: createJSONStorage(() => sessionStorage),
            partialize: (state: any) => {
                return { tableConfig: state.tableConfig, columnState: state.columnState, pagination: state.pagination }
            }
        }
    )
)   