import React, { useState } from 'react'
import { MenuItem, Menu, Tooltip, Button, IconButton, Divider, Typography } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTableStore } from 'state/TableStore';
import EditIcon from '@mui/icons-material/Edit';
import { getCurrentEnv, logger } from "../../../utils";
import { useUserStore } from 'state';

export const Actions: React.FC<any> = ({ row, pageName, isCurrentRowClicked, rowClickHandler }) => {
    const userConfig = useUserStore(state => state.userConfig)
    const config = useUserStore(state => state.config)
    const { usersPermissions } = userConfig;
    const { maxExtensions } = config.transactionViewing

    const setExtendModal = useTableStore(state => state.setExtendModal)
    const [tcns, setTcns] = useState<Array<any>>([])
    const [anchorEl, setAnchorEl] = useState(null);
    const openActions = Boolean(anchorEl);

    const currentRow = useTableStore((state) => state.currentRow)
    const setCurrentRow = useTableStore((state) => state.setCurrentRow)
    const actionsConfig = useTableStore(state => state.actionsConfig)
    const setRowClicked = useTableStore(state => state.setRowClicked);
    const zoomLevel = useTableStore(state => state.zoomLevel);

    const popoverId = openActions ? 'actions-popover' : undefined;

    const handleOpenActions = (e: any, key: string) => {
        e.stopPropagation()
        setCurrentRow(key)
        setAnchorEl(e.currentTarget);
    }

    const handleClose = (e: any) => {
        e.stopPropagation()
        setAnchorEl(null);
        setRowClicked(false);
    };

    const debugExtend = {
        identifier: "debug extendView",
        pageName: "transaction-summary",
        thingToDebug: "Actions",
        paramsToLog: {
            actionsConfig
        }
    }

    // logger(debugExtend)

    return (
        <>
            {
                pageName === 'user-management'
                    ? currentRow === row.key &&
                    <Button size='small' sx={{ width: `${67 / zoomLevel}px` , height: `${30 / zoomLevel}px` }} onClick={(e) => rowClickHandler(e, row)}>
                        <EditIcon sx={{ mr: '0.5rem', width: `${18 / zoomLevel}px`, height: `${18 / zoomLevel}px` }} />
                        <Typography variant="button" sx={{ fontSize: `${14 / zoomLevel}px`}}>
                            Edit
                        </Typography>
                    </Button>
                    : row.key && <Tooltip title="Open Actions">
                        <IconButton key={row.key}
                            sx={{ marginLeft: "", height: "20px", width: "20px", transform: `scale(${1 / zoomLevel})`, transformOrigin: "center" }}
                            onClick={(e: any) => {
                                e.stopPropagation();
                                handleOpenActions(e, row.key)
                            }
                            }>
                            <MoreVertIcon />
                        </IconButton>
                    </Tooltip>
            }
            {
                row.key === currentRow && (
                    <Menu
                        onClose={(e) => handleClose(e)}
                        slotProps={{ paper: { sx: { width: (pageName === 'transaction-summary' || pageName === 'applicant-details') ? '220px' : '270px', backgroundColor: "background.default", border: "none", display: 'flex', flexDirection: "column" } } }}
                        id={popoverId}
                        open={openActions}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        anchorEl={anchorEl}
                        MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                        {
                            (pageName === 'transaction-summary' || pageName === 'applicant-details' || pageName === 'rapback-summary') && (
                                actionsConfig.map((action, index) => {
                                    console.log('debug actions actionsCofnig: ', actionsConfig)
                                    const disableExtend = row.extendCount >= maxExtensions
                                    return (action.actionLabel === "Extend"
                                        ? usersPermissions?.extendTransactionRecord &&
                                        <MenuItem disabled={disableExtend} key={index}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                handleClose(e)
                                                action.actionHandler(e, row)
                                            }}
                                            sx={{ width: "270px", height: "36px", pointerEvents: disableExtend ? "none" : "" }}>
                                            {action.actionLabel}
                                        </MenuItem>
                                        : <MenuItem key={index}
                                            disabled={action.actionLabel === "No PDF available" ? true : false}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                handleClose(e)
                                                action.actionHandler(e, row)
                                            }}
                                            sx={{ width: '270px', height: "36px", }}>
                                            {action.actionIcon ? action.actionIcon : <></>}
                                            {action.actionLabel}
                                        </MenuItem>
                                    )
                                })
                            )
                        }
                        {
                            pageName === 'rapback-maintenance' && (
                                actionsConfig.map((action, index) => {
                                    return (
                                        action.actionLabel === "_divider"
                                            ? <Divider />
                                            : <MenuItem key={index}
                                                disabled={(action.actionLabel === "Edit Subscription" || action.actionLabel === "Unsubscribe") && (row.status?.toLowerCase() === "unsubscribed" || row.status?.toLowerCase().includes("pending"))}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    handleClose(e)
                                                    action.actionHandler(e, row)
                                                }}
                                                sx={{ width: "270px", height: "36px" }}>
                                                {action.actionLabel}
                                            </MenuItem>
                                    )
                                })

                            )
                        }
                        {
                            pageName === 'rapback-prenotifications' && (
                                actionsConfig.map((action, index) => {
                                    return (
                                        <MenuItem
                                            key={index}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                handleClose(e)
                                                action.actionHandler(e, row)
                                            }}
                                            sx={{ width: "270px", height: "36px" }}>
                                            {action.actionLabel}
                                        </MenuItem>
                                    )
                                })
                            )
                        }
                        {
                            pageName === 'rapback-validations' && (
                                actionsConfig.map((action, index) => {
                                    return (
                                        <MenuItem
                                            disabled={Math.sign(row.timeRemaining) < 1 ? true : false}
                                            key={index}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                handleClose(e)
                                                action.actionHandler(e, row)
                                            }}
                                            sx={{ width: "270px", height: "36px" }}>
                                            {action.actionLabel}
                                        </MenuItem>
                                    )
                                })
                            )
                        }
                    </Menu>
                )
            }
        </>
    )

}
