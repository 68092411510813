import { Typography } from '@mui/material';
import React from 'react';
import {  useTableStore } from 'state';
import { tableCellTypogrphyStyle } from "../styles"

interface CustomTableCellProps {
    cell: { dataIndex: string, label: string }
    value: string,
    children: any
}

export const SimpleTableCell: React.FC<CustomTableCellProps> = ({ cell, value, children }) => {
    const zoomLevel = useTableStore(state => state.zoomLevel)

    return (
        <Typography sx={{...tableCellTypogrphyStyle, fontSize: `${14 / zoomLevel}px`}}>
            {value && value}
            {children}
        </Typography>

    )
}

