
/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { TransactionRow } from "interfaces/hooks";
import { formatDate } from "utils/formatDate";
import dayjs from "dayjs";
import { urls } from "urls";
import { QueryParams } from "interfaces/common/QueryParams"
import { getAdvancedParamValues, getBasicParamValues, getRapBackParamValues } from "./getParamValues";
import { Config, PageNames, TableHeaderCells } from "interfaces/config";
import { getCurrentEnv } from "./getCurrentEnv";
import { States } from "interfaces/common";
import { table } from "console";
import { useRapbackPdfURL } from "hooks";
import { useEffect } from 'react'
import { UserConfig } from "state/UserStore";

const STATE = getCurrentEnv

const convertDate = (date: any, type: any) => {
    switch (type) {
        case 'dob':
            console.log('date for date of birth: ', date)
            const flippedDate = `${date.substring(4, 8)}${date.substring(0, 2)}${date.substring(2, 4)}`;
            return formatDate(flippedDate);
        case 'insertDate':
            console.log('date for insert date: ', date)
            return formatDate(date);
        case 'viewedDate':
            if (date === "N/A") {
                return "N/A"
            } else {
                return formatDate(date);
            }

        default:
            console.log('date for rapback: ', formatDate(date))
            return formatDate(date);
    }
}

const getFirstSSN = (ssn: string | undefined) => {
    if (ssn?.includes(',')) {
        ssn = ssn.split(',')[0];
        return ssn
    } else {
        return ssn;
    }
}

export const splitDataToPages = (data: any, pageSize: number, useTableStore: any) => {
    console.log('split data to pages data: ', data)
    return new Promise(async (resolve) => {
        if (data) {
            console.log('paginate data: ', data.length)
            const res: Array<any> = [];
            for (let i = 0; i < data.length; i += pageSize) {
                const chunk = data.slice(i, i + pageSize);
                res.push(chunk);
            }
            resolve(res);
        }
    })
}

export const extendViewing = async (id: any, expireDate: string, numDaysExtended: number, config: Config) => {
    console.log('debug extend extendModal dates: debug extenderViewing function config: ', { id, expireDate, numDaysExtended, config })
    const STATE = process.env.REACT_APP_CONFIG_STATE!

    const { hardLimit, softLimit, extendDays } = config.transactionViewing;
    // const transactionDate = useTableStore(state => state.transactionDate)

    console.log("extendViewing id: ", id);
    const controller = new AbortController();
    const { signal } = controller;
    console.log("extend process.env.NODE_ENV", process.env.NODE_ENV);
    const reportingUrl = urls.REPORTING;
    const failed = false;
    return new Promise(async (resolve, reject) => {
        try {
            const res = await fetch(
                `${process.env.NODE_ENV === 'production' ? reportingUrl : ""}/adjudication/transactions/extendViewing/${id}?expireDate=${expireDate}`,
                {
                    method: "POST",
                    credentials: "include",
                    signal,
                }
            );
            console.log('response', res);
            const status = res.status;
            if (status === 200) {
                /*const response = await res.json();
                console.log('checking the response', response)
                const message = response.message
                let msgSplit = message.split("for ")
                let extendedFor = parseInt(msgSplit[1].split(" ")[0])
                let date = dayjs().add(extendedFor, "days").format("LL")
                console.log('message date ', date)*/
                resolve({
                    status: res.status,
                    msg: `Transaction Extended 
                    The applicant has been extended ${numDaysExtended} days. Transaction extended through ${dayjs(expireDate).format('LL')}`
                });

            } else {
                console.log('failed status', status);
                resolve({ status: res.status, msg: `Transaction cannot be extended, ${hardLimit} day max limit extension has been reached,  please contact support.` });
            }

        } catch (e: any) {
            console.log("error extending viewing for id: ", id);
        }
    });
};

const createName = (nameString: string) => {
    let split = nameString.split(/[,]+/)
    let last = split[0]
    let firstMiddle = split[1].split(" ")
    firstMiddle.shift()
    let first = firstMiddle[0]
    let middle = firstMiddle[1]
    return [last, first, middle]
}

export const transformRejectData = (data: any, index: number) => {
    const rejectString = data.V1_field60
    let splitString = rejectString.split(/ - (.*)/)
    const row = {
        key: index,
        rejectIndex: index + 1,
        rejectCode: splitString[0],
        rejectDescription: splitString[1]
    }
    return row
}

const mapHitNoHitResponse = (result: string, transactionStatus: string) => {
    let errors = ["ERRT-Error",
                  "QC Reject",
                  "QC Reject NoRBsub",
                  "ERRT-Error NoRBsub",
                  "Error"]
    if (errors.includes(transactionStatus)) return "N/A"
    if (result === "Y") {
        return "Record Found"
    } else if (result === "N") {
        return "No Record Found"
    } else if (result === "NS") {
        return "Name Search"
    }
    else return "Results Pending"
}

export const transform = (data: any, i: number) => {
    console.log('useQueryTransactions transform data: ', data)
    let lastName, firstName, middleName;
    if (data.name) {
        [lastName, firstName, middleName] = createName(data.name);
    }

    const parseFullName = (name: string) => {
        if (name) {
            let arr = name.split(",")
            let firstName, middleName
            let lastName = arr[0]
            let firstMiddle = arr[1].trim().split(" ")
            firstName = firstMiddle[0]
            middleName = firstMiddle[1]
            return `${firstName} ${lastName}`
        } else {
            return ""
        }
    }

    const row: TransactionRow = {
        key: data.id ?? '-',
        view: data.id ?? '-',
        fullName: data.name ? parseFullName(data.name) : '-',
        firstName: (data.FIRST_NAME || data.firstName || firstName) ?? '-', //Last,First M || Last, First M
        lastName: (data.LAST_NAME || data.lastName || lastName) ?? '-', //Last,First M || Last, First M
        middleName: (data.MIDDLE_NAME || data.middleName || middleName) ?? '-',
        ueid: (data.UEID || data.ueid) ?? '-',
        ssn: data.ssn,
        dob: data.dob ? formatDate(data.dob) : '-',
        gender: data.gender ?? '-',
        race: data.race ?? '-',
        expireDate: data.expireDate,
        extendCount: data.extendCount,
        status: data.transactionStatus ? data.transactionStatus.replaceAll('"', "") : '-', //transactionStatus
        hitNoHit: data.transactionStatus ? mapHitNoHitResponse(data.hitNoHit, data.transactionStatus) : '-',
        transactionDate: data.insertDate ? formatDate(data.insertDate) : '-', //date convert to mm/dd/yyyy am/pm comes in year day month "2022-05-03T00:00:00Z" ask insertDate
        tcn: (data.TCN || data.tcn) ?? '-', //id
        transactionType: (data.TOT || data.tot) ?? '-', //transactionType
        agency: (data.AGENCY || data.agency) ?? '-', //agency
        secondaryOri: data.secondaryOri ?? '-',
        stateRFPCode: data.stateRFPCode ?? '-',
        stateRFPLiteral: data.stateRFPLiteral ?? '-', //this is GA thing. Will need additional transform logic for other states
        viewed: data.viewed,
        viewedDate: data.viewedDate ?? 'N/A',
        viewedUser: data.viewedUser,
        rfp: data.RFP,
        rbSub: data.rbSub 
    }
    return row;
};

const parseName = (name: string) => {
    let arr = name.split(", ")
    if (arr.length === 1) {
        return name
    }
    if (arr[1].includes(" ")) {
        let firstMiddle = arr[1].split(" ")
        console.log('firstMiddle: ', firstMiddle)
        return `${firstMiddle[0]} ${firstMiddle[1]} ${arr[0]}`
    } else {
        return `${arr[1]} ${arr[0]}`
    }
}

export const transformMaintenanceData = (data: any, index: number) => {
    console.log('transformMaintenacnceData data: ', data)

    /*const row = { key: data.id }

    const formatCell = (value: string, dataIndex: string) => {
        switch (dataIndex) {
            case "fullName":
                return parseName(value)

            case "dob":
                if (value != "N/A") return formatDate(value)
                break

            case "subscriptionDate":
                return formatDate(value)

            case "expirationDate":
                if (value != "N/A") return formatDate(value)
                break

            case "unsubscribeDate":
                if (value != "N/A") return formatDate(value)
                break

            default:
                return value

        }
    }


    tableHeaderCells.forEach((cell) => {
        row[cell.dataIndex] = formatCell(data[cell.dataIndex], cell.dataIndex)
    })

    return row*/


    return {
        key: data.id,
        fullName: parseName(data.fullName),
        dob: data.dob != "N/A" ? formatDate(data.dob) : "N/A",
        rapbackSubId: data.rapbackSubId,
        ssn: data.ssn === null ? "123-45-6789" : data.ssn,
        lsTcn: data.lsTcn,
        ori: data.ori,
        subscriptionDate: formatDate(data.subscriptionDate),
        expirationDate: data.expirationDate != "N/A" ? formatDate(data.expirationDate) : "N/A",
        deletionDate: data.deletionDate != "N/A" ? formatDate(data.deletionDate) : "N/A",
        status: data.status
    }
}

export const transformPrenotificationsData = (data: any, index: number) => {
    console.log(`debug validations prenotifications data: ${JSON.stringify(data, null, 2)}`)
    return {
        key: data.id,
        fullName: parseName(data.fullName),
        dob: data.dob != "N/A" ? formatDate(data.dob) : "N/A",
        ssn: data.ssn === "n/a" ? "123-45-6789" : data.ssn,
        rapbackSubId: data.rapbackSubId, //is this rapback id?
        primaryOri: data.ori,
        subscriptionDate: data.subscriptionDate != "N/A" ? formatDate(data.subscriptionDate) : "N/A",
        expirationDate: data.expirationDate != "N/A" ? formatDate(data.expirationDate) : "N/A",
        receivedDate: data.needsConfirmDate != "N/A" ? formatDate(data.needsConfirmDate) : "N/A"
    }
}

export const transformValidationsData = (data: any, index: number) => {
    console.log('debug validations transformValidationsData: ', data)
    return {
        key: data.id,
        fullName: parseName(data.fullName),
        dob: data.dob != "N/A" ? formatDate(data.dob) : "N/A",
        ssn: data.ssn === null ? "no-ssn" : data.ssn,
        oris: data.ori + " " + data.sOri,
        rapbackSubId: data.rapbackSubId, //is this rapback id?
        primaryOri: data.ori,
        //secondaryOri: data.s_ori,
        subscriptionDate: data.subscriptionDate != "N/A" ? formatDate(data.subscriptionDate) : "N/A",
        expirationDate: data.expirationDate != "N/A" ? formatDate(data.expirationDate) : "N/A",
        timeRemaining: data.daysToExpire,
    }
}

export const transformBatchPrintData = (data: any, index: any) => {
    return {
        key: data.id,
        firstName: data.FIRST_NAME,
        lastName: data.LAST_NAME,
        reasonFingerPrinted: data.RFP,
        status: data.transactionStatus,
        transactionDate: formatDate(data.insertDate),
        transactionNumber: data.TCN,
        ori: data.AGENCY,
        applicantType: data.cardType
    };
}

export const transformRapBackData = (data: any, i: number) => {
    console.log(`transformedData rapback inside STATE === States.GA ${STATE === States.GA} transform data: `, data)

    return {
        key: data.id, //id coould also be an index
        view: data.id,
        firstName: data.FIRST_NAME || data.firstName, //Last,First M || Last, First M
        lastName: data.LAST_NAME || data.lastName, //Last,First M || Last, First M
        middleName: (data.MIDDLE_NAME != "N/A" ? data.MIDDLE_NAME : "") || (data.middleName != "N/A" ? data.middleName : ""),
        agency: data.agency,
        ...STATE === States.GA ? { primaryOri: data.primaryOri, secondaryOri: data.secondaryOri } : { oris: data.oris },
        sid: data.sid,
        ssn: getFirstSSN(data.ssn),
        dob: convertDate(data.dob, 'dob'),
        reason: data.reason,
        isHit: data.isHit.toUpperCase(),
        rapbackDate: convertDate(data.rapbackDate, 'rapbackDate'),
        fileData: data.fileData,
        insertDate: convertDate(data.insertDate, 'insertDate'),
        viewed: data.viewed,
        viewedDate: convertDate(data.viewedDate, 'viewedDate'),
        viewedUser: data.viewedUser
    }
}

const checkInvalidDate = (value: any) => {
    if(value === "Invalid Date") {
        return "null"
    } else {
        return value
    }
}

export const generateRBScubscriptionQueryParams = (pageName: string, searchParams, filterLabels, filterNumbers, orderBy, order, param): QueryParams => {
    console.log('debug getAdvancedParamValues 101010101 debug getParamValues rb simple search: ', {
        searchParams,
        conditionalA: getAdvancedParamValues("dob", searchParams, "lowerDate"),
        conditionalB: getAdvancedParamValues("lowerDobDate", searchParams),
        value: param?.column === "dob" ? getAdvancedParamValues("dob", searchParams, "lowerDate") : getAdvancedParamValues("lowerDobDate", searchParams),
        col: param?.column,
        param
    })

    return [
        {
            "value": 'true', // 'true' or 'false' only returning if isAdmin  is set to true
            "name": "isAdmin"
        },
        {
            "value": "null", //getAdvancedParamValues("days", searchParams, undefined, pageName),
            "name": "days"
        },
        {
            "value": orderBy === "receivedDate" ? "needsConfirmDate" : orderBy === "primaryOri" ? "ori" : orderBy, //"default" is value in an example
            "name": "orderBy"
        },
        {
            "value": order,
            "name": "order"
        },
        {
            "value": getAdvancedParamValues("fullName", searchParams),
            "name": "fullName"
        },
        {
            "value": getAdvancedParamValues("firstName", searchParams),
            "name": "firstName"
        },

        {
            "value": getAdvancedParamValues("lastName", searchParams),
            "name": "lastName"
        },
        {
            "value": getAdvancedParamValues("ssn", searchParams),
            "name": "ssn"
        },
        {
            "value": getAdvancedParamValues("rfp", searchParams),
            "name": "rfp"
        },
        {
            "value": getAdvancedParamValues("lsTcn", searchParams),
            "name": "lsTcn"
        },
        {
            "value": getAdvancedParamValues("afisTcn", searchParams),
            "name": "afisTcn"
        },
        {
            "value": getAdvancedParamValues("primaryOri", searchParams), //figure out column for this
            "name": "ori"
        },
        {
            "value": getAdvancedParamValues("secondaryOri", searchParams),
            "name": "secondaryOri"
        },
        {
            "value": pageName === "rapback-maintenance" ? JSON.stringify(filterLabels) : "null",
            "name": "selectedStatus"
        },
        {
            "value": getAdvancedParamValues("status", searchParams),
            "name": "status"
        },
        {
            "value": getAdvancedParamValues("cri", searchParams),
            "name": "cri"
        },
        {
            "value": `${pageName === "rapback-maintenance" ? "null" : false}`,
            "name": "isPending"
        },

        {
            "value": `${pageName === "rapback-validations" ? true : "null"}`,
            "name": "isActive"
        },
        // Comment out for now until backend can handle different filtering days - Joseph
        // {
        //     "value": `${
        //         pageName === "rapback-validations" ?  
        //             (filterLabels.length === 0 ? "0" : filterLabels.length === filterNumbers.length ? "60" :
        //                 filterLabels.some(num => filterNumbers.includes(num))
        //                 ? Math.max(...filterLabels).toString()
        //                 : "60") 
        //         : "null"
        //     }`,
        //     "name": "expiryDaysLimit"
        // },
        {
            "value": `${(pageName === "rapback-validations") ? "60" : "null"}`,
            "name": "expiryDaysLimit"
        },
        {
            "value": `${pageName === "rapback-validations" ? getAdvancedParamValues("daysToExpire", searchParams) : "null"}`,
            "name": "daysToExpire"
        },
        {
            "value": `${pageName === "rapback-prenotifications" ? true : "null"}`,
            "name": "needsConfirm"
        },

        {
            "value": getAdvancedParamValues("rapbackSubId", searchParams),
            "name": "rapbackSubId" //this query param name should be updated on backend to match what comes back in response rapbackSubId
        },
        {
            "value": param?.column === "dob" ? checkInvalidDate(getAdvancedParamValues("dob", searchParams, "lowerDate")) : checkInvalidDate(getAdvancedParamValues("lowerDobDate", searchParams)),
            "name": "lowerDobDate"
        },
        {
            "value": param?.column === "dob" ? checkInvalidDate(getAdvancedParamValues("dob", searchParams, "upperDate")) : checkInvalidDate(getAdvancedParamValues("upperDobDate", searchParams)),
            "name": "upperDobDate"
        },
        {
            "value": param?.column === "fingerPrintDate" ? checkInvalidDate(getAdvancedParamValues("fingerPrintDate", searchParams, "lowerDate")) : checkInvalidDate(getAdvancedParamValues("lowerFingerPrintDate", searchParams)),
            "name": "lowerFingerPrintDate"
        },
        {
            "value": param?.column === "fingerPrintDate" ? checkInvalidDate(getAdvancedParamValues("fingerPrintDate", searchParams, "upperDate")) : checkInvalidDate(getAdvancedParamValues("upperFingerPrintDate", searchParams)),
            "name": "upperFingerPrintDate"
        },
        {
            "value": param?.column === "expirationDate"
                ? checkInvalidDate( getAdvancedParamValues("expirationDate", searchParams, "lowerDate") )
                : checkInvalidDate( getAdvancedParamValues("lowerExpirationDate", searchParams)),
            "name": "lowerExpirationDate"
        },
        {
            "value": param?.column === "expirationDate"
                ? checkInvalidDate(getAdvancedParamValues("expirationDate", searchParams, "upperDate"))
                : checkInvalidDate(getAdvancedParamValues("upperExpirationDate", searchParams)),
            "name": "upperExpirationDate"
        },
        {
            "value": param?.column === "subscriptionDate" ? checkInvalidDate(getAdvancedParamValues("subscriptionDate", searchParams, "lowerDate")) : checkInvalidDate(getAdvancedParamValues("lowerSubscriptionDate", searchParams)),
            "name": "lowerSubscriptionDate"
        },
        {
            "value": param?.column === "subscriptionDate" ? checkInvalidDate(getAdvancedParamValues("subscriptionDate", searchParams, "upperDate")) : checkInvalidDate(getAdvancedParamValues("upperSubscriptionDate", searchParams)),
            "name": "upperSubscriptionDate"
        },
        {
            "value": param?.column === "receivedDate" ? checkInvalidDate(getAdvancedParamValues("receivedDate", searchParams, "lowerDate")) : checkInvalidDate(getAdvancedParamValues("lowerNeedsConfirmDate", searchParams)), //received data from pre-notifications
            "name": "lowerNeedsConfirmDate"
        },
        {
            "value": param?.column === "receivedDate" ? checkInvalidDate(getAdvancedParamValues("receivedDate", searchParams, "upperDate")) : checkInvalidDate(getAdvancedParamValues("upperNeedsConfirmDate", searchParams)),
            "name": "upperNeedsConfirmDate"
        },
        {
            "value": param?.column === "deletionDate" ? checkInvalidDate(getAdvancedParamValues("deletionDate", searchParams, "lowerDate")) : checkInvalidDate(getAdvancedParamValues("lowerDeletionDate", searchParams)), //received data from pre-notifications
            "name": "lowerDeletionDate"
        },
        {
            "value": param?.column === "deletionDate" ? checkInvalidDate(getAdvancedParamValues("deletionDate", searchParams, "upperDate")) : checkInvalidDate(getAdvancedParamValues("upperDeletionDate", searchParams)),
            "name": "upperDeletionDate"
        },
    ]
}


export const generateQueryParams = (softLimit: string, pageName: string, userConfig: UserConfig, hardLimit: number, isAdmin, searchParams, filterLabels, orderBy, order, param): QueryParams => {
    console.log('debug getAdvancedParamValues generateQueryParams searchParams: ', { searchParams, param} )

    console.log('debug query params days: ', {
        upperLimit: getAdvancedParamValues("upperDate", searchParams),
        days: dayjs().add(dayjs().diff(getAdvancedParamValues("upperDate", searchParams), "days"), 'days'),
        hardLimit,
        diff: dayjs().diff(getAdvancedParamValues("upperDate", searchParams), 'days')
    })

    const getDays = () => {
        let upperDate = getAdvancedParamValues("upperDate", searchParams)
        let lowerDate = getAdvancedParamValues("lowerDate", searchParams)
        console.log(`101010101 debug getParamValue getDays: `, { upperDate, lowerDate })
        if (lowerDate != 'null') {
            console.log(`101010101 debug getParamValue getDays returns: `, dayjs().diff(lowerDate, 'days'))

            return dayjs().diff(lowerDate, 'days').toString()
        } else {
            console.log(`101010101 debug getParamValue getDays returns: `, hardLimit)
            return hardLimit.toString()
        }
    }

    return [
        {
            "name": "type",
            "value": "Original"
        },
        {
            "name": "selectedTransactionStatus",
            "value": JSON.stringify(filterLabels)
        },
        {
            "value": isAdmin,
            "name": "isAdmin"
        },
        {
            "value": getDays(),
            "name": "days"
        },
        {
            "value": orderBy,
            "name": "orderBy"
        },
        {
            "value": getAdvancedParamValues("firstName", searchParams),
            "name": "firstName"
        },
        {
            "value": getAdvancedParamValues("middleName", searchParams),
            "name": "middleName"
        },
        {
            "value": getAdvancedParamValues("lastName", searchParams),
            "name": "lastName"
        },
        {
            "value": getAdvancedParamValues("fullName", searchParams),
            "name": "fullName"
        },
        {
            "value": getAdvancedParamValues("ueid", searchParams),
            "name": "ueid"
        },
        {
            "value": getAdvancedParamValues("tcn", searchParams),
            "name": "tcn"
        },
        {
            "value": getAdvancedParamValues("transactionType", searchParams),
            "name": "tot"
        },
        {
            "value": getAdvancedParamValues(STATE === "ga" ? "stateRFPCode" : "rfp", searchParams),
            "name": STATE === "ga" ? "stateRFPCode" : "rfp"
        },
        ...STATE === "ga" ? [{
            "value": getAdvancedParamValues("stateRFPLiteral", searchParams),
            "name": "stateRFPLiteral"
        }] : [],
        {
            "value": getAdvancedParamValues("agency", searchParams),
            "name": "ori"
        },
        {
            "value": getAdvancedParamValues("hitNoHit", searchParams),
            "name": "hitNoHit"
        },
        {
            "value": getAdvancedParamValues("ssn", searchParams),
            "name": "ssn"
        },
        {
            "value": "null",
            "name": "dob"
        },
        {
            "value": param?.column === "dob"
                ? checkInvalidDate(getAdvancedParamValues("dob", searchParams, "lowerDate", pageName))
                : checkInvalidDate(getAdvancedParamValues("lowerDobDate", searchParams)),
            "name": "lowerDobDate"
        },
        {
            "value": param?.column === "dob" 
                ? checkInvalidDate(getAdvancedParamValues("dob", searchParams, "upperDate", pageName))
                : checkInvalidDate(getAdvancedParamValues("upperDobDate", searchParams)),
            "name": "upperDobDate"
        },
        {
            "value": getAdvancedParamValues("gender", searchParams),
            "name": "gender"
        },
        {
            "value": getAdvancedParamValues("race", searchParams),
            "name": "race"
        },
        {
            "value": getAdvancedParamValues("secondaryOri", searchParams),
            "name": "secondaryOri"
        },
        {
            "value": getAdvancedParamValues("status", searchParams),
            "name": "transactionStatus"
        },
        {
            "value": param?.column === "transactionDate"
                ? getAdvancedParamValues("transactionDate", searchParams, "lowerDate", pageName)
                : getAdvancedParamValues("lowerDate", searchParams),
            "name": "lowerDate"
        },
        {
            "value": param?.column === "transactionDate"
                ? getAdvancedParamValues("transactionDate", searchParams, "upperDate", pageName)
                : getAdvancedParamValues("upperDate", searchParams),
            "name": "upperDate"
        },
        {
            "value": getAdvancedParamValues("singleDate", searchParams),
            "name": "singleDate"
        },
        {
            "value": getAdvancedParamValues("rbSub", searchParams),
            "name": "rbSub"
        }
    ]
}

export const generateAgencyQueryParams = (orderBy, order, searchParams): QueryParams => {
    return [
        {
            "value": orderBy ? orderBy : "default",
            "name": "orderBy"
        },

        {
            "value": getBasicParamValues("ori", searchParams),
            "name": "ori"
        },
        {
            "value": getBasicParamValues("secondaryOri", searchParams),
            "name": "secondaryOri"
        },
        {
            "value": getBasicParamValues("agencyCity", searchParams),
            "name": "agencyCity"
        },
        {
            "value": getBasicParamValues("agencyName", searchParams),
            "name": "agencyName"
        },
        {
            "value": getBasicParamValues("agencyState", searchParams),
            "name": "agencyState"
        },
        {
            "value": getBasicParamValues("agencyAddress", searchParams),
            "name": "agencyAddress"
        },
        {
            "value": getBasicParamValues("agencyZipcode", searchParams),
            "name": "agencyZipcode"
        },
        {
            "value": getBasicParamValues("agencyEmail", searchParams),
            "name": "agencyEmail"
        },
        {
            "value": getBasicParamValues("distributionMethod", searchParams),
            "name": "distributionMethod"
        },
        {
            "value": getBasicParamValues("reasonFingerPrinted", searchParams),
            "name": "reasonFingerPrinted"
        },
        {
            "value": "null",
            "name": "lowerInsertDate"
        },
        {
            "value": "null",
            "name": "upperInsertDate"
        },
        {
            "value": order,
            "name": "order"
        }
    ]
}

export const generateBatchPrintQueryParams = (orderBy, param, searchParams): QueryParams => {
    return [
        {
            "value": "[{\"ori\": \"ALL\",\"rfp\": [\"ALL\"]}]",
            "name": "ori_rfp_json"
        },
        {
            "value": "true", // 'true' or 'false' 
            "name": "isAdmin"
        },
        {
            "value": orderBy,
            "name": "orderBy"
        },
        {
            "value": getAdvancedParamValues("days", searchParams),
            "name": "days"
        },
        {
            "value": getAdvancedParamValues("firstName", searchParams),
            "name": "firstName"
        },
        {
            "value": getAdvancedParamValues("middleName", searchParams),
            "name": "middleName"
        },
        {
            "value": getAdvancedParamValues("lastName", searchParams),
            "name": "lastName"
        },
        {
            "value": getAdvancedParamValues("transactionNumber", searchParams),
            "name": "tcn"
        },
        {
            "value": getAdvancedParamValues("transactionType", searchParams),
            "name": "tot"
        },
        {
            "value": getAdvancedParamValues("reasonFingerprinted", searchParams),
            "name": "rfp"
        },
        {
            "value": getAdvancedParamValues("ori", searchParams),
            "name": "ori"
        },
        {
            "value": getAdvancedParamValues("secondaryOri", searchParams),
            "name": "secondaryOri"
        },
        {
            "value": "Decision",
            "name": "transactionStatus"
        },
        {
            "value": param?.column === "transactionDate" ? getAdvancedParamValues("transactionDate", searchParams, "lowerDate") : getAdvancedParamValues("lowerDate", searchParams),
            "name": "lowerDate"
        },
        {
            "value": param?.column === "transactionDate" ? getAdvancedParamValues("transactionDate", searchParams, "upperDate") : getAdvancedParamValues("upperDate", searchParams),
            "name": "upperDate"
        },
        {
            "value": "null",
            "name": "printLocation"
        },
        {
            "value": "null",
            "name": "printlocation"
        },
        {
            "value": "P",
            "name": "resultsDistribution"
        },
        {
            "value": "null",
            "name": "cardType"
        },
        {
            "value": "null",
            "name": "fbiRapsheet"
        },
        {
            "value": "null",
            "name": "stateRapsheet"
        }
    ]
}

export const generateRapBackQueryParams = (filterLabels, isAdmin, orderBy, order, searchParams): QueryParams => {
    console.log('searchParams: ', searchParams)
    return [
        {
            "value": isAdmin,
            "name": "isAdmin"
        },
        {
            "value": '90',
            "name": "days"
        },
        {
            "value": orderBy ? orderBy : "default",
            "name": "orderBy"
        },
        {
            "name": "selectedLastViewStatus",
            "value": JSON.stringify(filterLabels)
        },
        {
            "value": getRapBackParamValues("firstName", searchParams),
            "name": "firstName"
        },
        {
            "value": getRapBackParamValues("tcn", searchParams),
            "name": "tcn"
        },
        {
            "value": getRapBackParamValues("middleName", searchParams),
            "name": "middleName"
        },
        {
            "value": getRapBackParamValues("lastName", searchParams),
            "name": "lastName"
        },
        {
            "value": getRapBackParamValues("sid", searchParams),
            "name": "sid"
        },
        {
            "value": getRapBackParamValues("ssn", searchParams),
            "name": "ssn"
        },
        {
            "value": getRapBackParamValues("reasonFingerPrinted", searchParams),
            "name": "reason"
        },
        {
            "value": getRapBackParamValues("agency", searchParams),
            "name": "agency"
        },
        {
            "value": getRapBackParamValues("isHit", searchParams) === "hit" ? "Y" : "null",
            "name": "isHit"
        },
        ...STATE === "ga"
            ? [{ 
                "value": getRapBackParamValues("primaryOri", searchParams), 
                "name": "primaryOri" }, 
                { "value": getRapBackParamValues("secondaryOri", searchParams), 
                  "name": "secondaryOri" 
                }]
            : [{
                "value": getRapBackParamValues("oris", searchParams),
                "name": "ori"
            }],
        {
            "value": "null",
            "name": "rapbackSubId"
        },
        {
            // not in the mui table for dob
            "value": getRapBackParamValues("dob", searchParams),
            "name": "lowerDobDate"
        },
        {
            "value": getRapBackParamValues("dob", searchParams),
            "name": "upperDobDate"
        },
        {
            // no rapback date
            "value": getRapBackParamValues("rapbackdate", searchParams), // dayjs(rapbackdate[0]).format("YYYYMMDD") ,
            "name": "lowerRapbackDate"
        },
        {
            "value": getRapBackParamValues("rapbackdate", searchParams),
            "name": "upperRapbackDate"
        },
        {
            "value": getRapBackParamValues("insertDate", searchParams, "lowerDate"),
            "name": "lowerInsertDate"
        },
        {
            "value": getRapBackParamValues("insertDate", searchParams, "upperDate"),
            "name": "upperInsertDate"
        },
        {
            "value": order,
            "name": "order"
        }
        // {
        //     // only insert Date in the mui table
        //     "value": getParamValues("insertdate", "lowerDate") ,
        //     "name": "lowerInsertDate"
        // },
        // {
        //     "value": getParamValues("insertdate", "upperDate"),
        //     "name": "upperInsertDate"
        // }
    ]
}