import { useCallback, useEffect, useState, useRef } from "react";
import { urls } from "../urls";
import { OriGroup, UserRow } from "interfaces/hooks";
import { filterStatus, usersTransform } from "utils";
import { Group } from "interfaces/config";
import { useAuth } from "./useAuth";
import { handleUnauthorized } from "utils/handleUnauthorized";
import { useUserStore } from "state";
import { CognitoUser, OriObject, SoriObject, User } from '@apollo/user-wrapper';

interface UserListCountBody {
    AttributesToGet?: [string] | undefined
    Filter?: string | undefined
    Limit?: number | undefined
    PaginationToken?: string | undefined
    group?: string | string[] | undefined
}

export const useUserManagement = (userRole, currentStatus, currentSecurityLevel, searchParams) => {
    //const [paginationToken, setPaginationToken] = useState<string | undefined>()
    const [mappedData, setUserMangementData] = useState<Array<UserRow>>([])
    const [totalRecords, setTotalRecords] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const listUsers: string = `${process.env.NODE_ENV === "production" ? urls.USER_ADMIN : ""}/userAdmin/listUsers`
    const runCount = useRef(0);
    const { logout } = useAuth();
    const config = useUserStore(state => state.config);
    const groupConfig = config?.cognito?.userRoles
    const configGroupList = groupConfig?.map((group: { value: string, label: string, isAdmin: boolean }) => group?.value);

    useEffect(() => {
        console.log('userManagementData: ', mappedData)
        setTotalRecords(mappedData.length)
    }, [mappedData.length])

    useEffect(() => {
        runCount.current = runCount.current + 1;
        if (userRole) fetchUsers()
    }, [searchParams, userRole])

    const fetchUsers = async () => {

        const body: UserListCountBody = {
            group: userRole
        }
        setLoading(true)

        try {
            const data: any = await (await fetch(listUsers, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(body)
            })).json();

            handleUnauthorized(data.message, logout);

            console.log('userManagement data: ', data)

            const transformedData = data?.users?.map((user: User) => {
                return usersTransform(user, groupConfig, searchParams)
            }).filter((el) => {
                return el != undefined
            })

            setUserMangementData(transformedData)

            setLoading(false)
            return
        } catch (e: unknown) {
            console.log('userManagement caught error: ', e)
        }
    }

    const updateUserGroup = async (groupName: string, user: User) => {
        const nonCaresGroups: string[] = user?.jsonData?.access.groups?.filter((group: string) => !configGroupList?.includes(group)) ?? [];
        nonCaresGroups.push(groupName);

        const body = {
            groupName,
            user: {
                ...user,
                jsonData: {
                    ...user?.jsonData,
                    access: {
                        ...user?.jsonData?.access,
                        groups: nonCaresGroups
                    }
                }
            }
        };

        const addUserToGroup: string = `${process.env.NODE_ENV === "production" ? urls.USER_ADMIN : ""}/userAdmin/addUserToGroup`;
        let resp;
        try {
            resp = await (await fetch(addUserToGroup, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(body)
            })).json()
            console.log('addUserToGroup resp: ', resp)
        } catch (e) {
            console.log('Error setting group: ', resp?.message)
        }
        return resp
    }

    const updateUser = useCallback(async (formState: any) => {
        const updateUser: string = `${process.env.NODE_ENV === "production" ? urls.USER_ADMIN : ""}/userAdmin/updateUser`
        const { firstName, lastName, email, oriRfpGroups, status, securityLevel, user } = formState
        const oris: Array<OriObject> = [];
        const soris: Array<SoriObject> = [];
        oriRfpGroups?.forEach((group: OriGroup) => {
            if (group?.primaryOri?.value && group?.rfp?.value) {
                oris.push({ ori: group.primaryOri.value, rfp: [group.rfp.value || "ALL"] });
            } else if (group?.secondaryOri?.value && group?.rfp?.value) {
                soris.push({ sori: group.secondaryOri.value, rfp: [group.rfp.value || "ALL"] });
            }
        });

        const body: User = {
            ...user,
            jsonData: {
                ...user?.jsonData,
                access: {
                    ...user?.jsonData?.access,
                    oris,
                    soris
                },
                userInfo: {
                    ...user?.jsonData?.userInfo,
                    firstName,
                    lastName,
                    email
                }
            }
        }

        if (filterStatus(status) !== filterStatus(currentStatus)) {
            updateUserStatus(filterStatus(status), body);
        }

        if (securityLevel !== currentSecurityLevel) {
            const groupName = groupConfig?.filter((group: { value: string, label: string, isAdmin: boolean }) => group?.label === securityLevel);
            if (groupName[0]) {
                updateUserGroup(groupName[0]?.value, body);
            }
        }

        let resp;
        setLoading(true)
        try {
            resp = await (await fetch(updateUser, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(body)
            })).json()

            if (resp.code === 200) {
                fetchUsers()
            }

        } catch (e: any) {
            console.log('Error updating user: ', e)
        }
        setLoading(false)
        return resp
    }, [currentStatus, currentSecurityLevel])

    const updateUserStatus = async (status: boolean, user: User) => {
        const userJsonData: CognitoUser = user.jsonData;
        userJsonData.enabled = status;
        user.jsonData = userJsonData;

        const updateUserStatus: string = `${process.env.NODE_ENV === "production" ? urls.USER_ADMIN : ""}/userAdmin/manageUserStatus`
        let resp;
        try {
            resp = await (await fetch(updateUserStatus, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(user)
            })).json()

            console.log('addUserToGroup resp: ', resp)
        } catch (e) {
            console.log('Error setting group: ', resp?.message)
        }
        return resp
    }

    const createUser = async (userName: string, email: string, lastName: string, firstName: string, oriRfpGroups: any[], status: boolean, securityLevel: string) => {
        const oris: Array<OriObject> = [];
        const soris: Array<SoriObject> = [];
        oriRfpGroups?.forEach((group: OriGroup) => {
            if (group?.primaryOri?.value && group?.rfp?.value) {
                oris.push({ ori: group.primaryOri.value, rfp: [group.rfp.value || "ALL"] });
            } else if (group?.secondaryOri?.value && group?.rfp?.value) {
                soris.push({ sori: group.secondaryOri.value, rfp: [group.rfp.value || "ALL"] });
            }
        });

        const body: User = {
            userId: userName,
            jsonData: {
                access: {
                    scope: 'mc',
                    oris,
                    soris,
                    groups: [securityLevel]
                },
                userInfo: {
                    email,
                    firstName,
                    lastName
                },
                enabled: status
            }
        }

        const createUser: string = `${process.env.NODE_ENV === "production" ? urls.USER_ADMIN : ""}/userAdmin/createUser`;
        let resp;
        try {
            resp = await (await fetch(createUser, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(body)
            })).json()
            return resp
        } catch (e) {
            console.log('Error creating user: ', resp?.message)
        }
    }

    const resetPassword = async (user: string) => {
        let body = {
            "Username": user
        }

        const updateUserStatus: string = `${process.env.NODE_ENV === "production" ? urls.USER_ADMIN : ""}/userAdmin/resetUserMFA`;
        let resp;
        try {
            resp = await (await fetch(updateUserStatus, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(body)
            })).json()
        } catch (e) {
            console.log('Error setting group: ', resp?.message)
        }

        return resp
    }

    const resendTempPassword = async (user: string) => {
        let body = {
            "Username": user
        }

        const updateUserStatus: string = `${process.env.NODE_ENV === "production" ? urls.USER_ADMIN : ""}/userAdmin/resendTempPassword`;
        let resp;
        try {
            resp = await (await fetch(updateUserStatus, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(body)
            })).json()

            console.log('addUserToGroup resp: ', resp);
        } catch (e) {
            console.log('Error setting group: ', resp?.message);
        }
        return resp
    }

    const listUserStatus = async (user: string) => {
        let body = {
            "Username": user
        };

        const listUserStatus: string = `${process.env.NODE_ENV === "production" ? urls.USER_ADMIN : ""}/userAdmin/listUserStatus`;
        let resp;
        try {
            resp = await (await fetch(listUserStatus, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(body)
            })).json()
        } catch (e) {
            console.log('Error getting user status: ', resp?.message);
        }
        return resp
    }


    return { loading, error, mappedData, runCount: runCount.current, totalRecords, updateUserGroup, createUser, updateUserStatus, updateUser, resetPassword, resendTempPassword, listUserStatus }
}