
/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React, { useEffect } from "react";
import { urls } from '../urls'


export const useApplicantData = (registrationId: string) => {
    const [applicantData, setApplicantData] = React.useState<any>();
    const [responseAssociationData, setResponseAssociationData] = React.useState<any>();

    const reportingUrl = urls.REPORTING
    const personUrl = urls.PERSON;

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        let fetchReportingsUrl = `${process.env.NODE_ENV === 'production' ? reportingUrl : ""}/adjudication/association/${registrationId}`;

        const fetchUrls = async () => {
            try {
                const { associations } = await (await fetch(fetchReportingsUrl, {
                    credentials: "include",
                    signal
                })).json();

                console.log('debugging tcn click results associations: ', { associations, filtered: associations.filter((association: any) => association['type'] === 'Response') })

                if (associations.length) {
                    const filteredAssociations = associations.filter((association: any) => association.type === 'Response');
                    setResponseAssociationData(filteredAssociations)

                    associations.map(async (repAssociation: any) => {
                        let fetchingPersonUrl = `${process.env.NODE_ENV === 'production' ? personUrl : ""}/registration/${repAssociation.id}`;
                        const data = await (await fetch(fetchingPersonUrl, {
                            credentials: "include",
                            signal
                        })).json();

                        console.log('debugging tcn click results associations data: ', { filteredAssociations, data, associations });

                        if (data) {
                            setApplicantData({ ...data, tcn: repAssociation.tcn });

                        }
                    });

                }

            } catch (err: any) {
                console.log('ERROR => ', err);
            }
        }

        fetchUrls();

    }, [registrationId, reportingUrl, personUrl])

    return { applicantData, responseAssociationData };
};
