export const getEnvS3Name = (env: string) => {
    switch (env) {
        case 'meprod':
            return 'meprodathenabuckets-meprodathenainputbucket86c53d-14vfbk6r1bzl1';
        case 'mepreprod':
            return 'mepreprodathenabuckets-mepreprodathenainputbucket-179kki9o4seq9';
        case 'nmpreprod':
            return 'nmpreprodathenabuckets-nmpreprodathenainputbucket-cgeg10fi6rd9';
        case 'caresnmdpsprod':
            return 'caresnmdpsprodathenabuck-caresnmdpsprodathenainpu-vi9ad14v2839';
        case 'dev':
            return 'devathenabuckets-devathenainputbucketdf2eb022-owwaq0ac6ps';
        case 'dev2':
            return 'dev2athenabuckets-dev2athenainputbucketd15b5775-usywk88m108d';
        case 'demo':
            return 'demoathenabuckets-demoathenainputbucketaceab7bf-5b85kvyjrrea';
        case 'gapreprod':
            return 'mc-default-bucket-stack-mcdefaultbucketinputa9022d-g2dl2gifddgt';
        case 'staging':
            return 'mc-default-bucket-stack-mcdefaultbucketinputa9022d-nr3nqgwpdcwd';
        case 'ga':
            return 'mc-default-bucket-stack-mcdefaultbucketinputa9022d-zudsapivll91';
    }
}

