import { Button } from '@mui/material';
import { TableConfig } from 'interfaces/config';
import React from 'react';
import { useTableStore } from 'state';

interface CustomTableCellProps {
    row: any
    value: string
    index: string
    tableConfig: TableConfig,
}

export const ButtonTableCell: React.FC<CustomTableCellProps> = ({ row, value, index, tableConfig }) => {

    const buttonCellHandlers = useTableStore(state => state.buttonCellHandlers)
    console.log('debug tableConfig ButtonTableCell buttonCellHandlers: ', buttonCellHandlers)
    const { clickHandler } = buttonCellHandlers[index]
    console.log('debug tableConfig ButtonTableCell clickHandler: ', clickHandler)
    const zoomLevel = useTableStore(state => state.zoomLevel)

    return (

        <Button onClick={(e) => clickHandler(e, row)}
            sx={{ justifyContent: "flex-start", minWidth: "max-content", padding: 0, transform: `scale(${1 / zoomLevel})`, transformOrigin: "left"  }}
            variant="text">
            {value}
        </Button>

    )
}